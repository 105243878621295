<template>
  <Modal v-show="isModalVisible" @close="closeModal">
    <template v-slot:header> Redigera målgrupp </template>
    <template v-slot:body>
          <form @submit.prevent="update">
      <div class="form-group">
        <label>Namn</label>
        <input v-model="form.name" class="form-control" required />
      </div>
      <!-- <div class="form-group">
        <label>Huvudgrupp</label>
        <select
          v-model="form.stores"
          @change="onChangeMethod(form.stores.id)"
          class="form-select form-select-md"
          aria-label=".form-select-sm"
          required
        >
          <option v-for="{ id, name } in articles" :key="id" :value="{name: name, id: id}">
            {{ name }}
          </option>
        </select>
      </div>
      -->
      <div class="form-group">
        <label>Välj målgruppsartiklar</label><br>
        <button @click="showSelModal" type="button" class="btn createBtn">+</button>
        <SelectionModal v-bind:editChecked="{mainGroup, underGroup}" @finishedData="logChange" v-if="isSelModalVisible" @close="closeSelModal">
             <template v-slot:header><h3>Välj huvudgrupper och artikelgrupper!</h3></template>
        </SelectionModal>
      </div>

      <!--
      <div class="form-group">
        <label>ArtikelGrupp</label>
        <select
          v-model="form.articleGroup"
          class="form-select form-select-md"
          aria-label=".form-select-sm"
          required
        >
          <option v-for="item in articleList" :key="item" :value="item">
            {{ item }}
          </option>
         </select>
      </div> -->
      <div class="form-group">
        <label>Från köp</label>
        <input
          v-model="form.firstPurchase"
          type="date"
          class="form-control"
          required
        />
      </div>

      <div class="form-group">
        <label>Till köp</label>
        <input
          v-model="form.lastPurchase"
          type="date"
          class="form-control"
          required
        />
      </div>

      <button @click="closeModal()" type="submit" class="btn btn-success mt-3">Redigera målgrupp</button>
    </form>
    </template>
  </Modal>
</template>

<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { getTarget, updateTarget, useLoadGroup, getGroup } from "@/firebase";
import Modal from "./Modal.vue";
import SelectionModal from "./TargetSelection.vue";

export default {
  components: { 
    Modal,
    SelectionModal,
  },
  data() {
    return {
      isModalVisible: false,
      isSelModalVisible: false,
      articleList: [],
      mainGroup: [],
      underGroup: [],
    }
  },
  setup() {
    const router = useRouter();
    const articles = useLoadGroup();

    const form = reactive({
      name: "",
      stores: "",
      articleGroup: "",
      firstPurchase: "",
      lastPurchase: "",
    });

    const timeConvert = (time) => {
      var date = new Date(time._seconds * 1000);

      var MyDateString =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2);

      return MyDateString;
    };

    const editOpened = async (id) => {
      const target = await getTarget(id);
      form.name = target.data.name;
      form.stores = target.data.stores;
      form.articleGroup = target.data.articleGroup;
      form.firstPurchase = timeConvert(target.data.firstPurchase);
      form.lastPurchase = timeConvert(target.data.lastPurchase);

    };

    return { form, editOpened, timeConvert, articles, router };
  },
  methods: {
    async onChangeMethod(id) {
      const groups = await getGroup(id);
      return this.articleList = groups.articleGroups;
    },
    showModal(id) {
      this.isModalVisible = true;
      this.identifySearch = id;
      this.editOpened(id);
    },
    closeModal() {
      this.isModalVisible = false;
    },
    showSelModal() {
      this.isSelModalVisible = true;
      this.mainGroup = this.form.stores;
      this.underGroup = this.form.articleGroup;
    },
    closeSelModal() {
      this.isSelModalVisible = false;
    },
    async update () {
      if(this.form.name !== "") {
        console.log(this.form.firstPurchase)
        if((this.form.stores.length + this.form.articleGroup.length) > 0) {
          this.form.firstPurchase = new Date(this.form.firstPurchase).getTime();
          this.form.lastPurchase = new Date(this.form.lastPurchase).getTime();
          console.log(this.form.firstPurchase)
          await updateTarget(this.identifySearch, { ...this.form });
          this.router.push("/target");
          this.form.name = "";
          this.form.stores = "";
          this.form.articleGroup = "";
          this.form.firstPurchase = "";
          this.form.lastPurchase = "";
          this.data = [];
        } else {
          alert("Välj artikel!")
        }
      } else {
        alert("Du har inte fyllt i allt korrekt!")
      }
    },
    logChange (event) {
      this.mainGroup = [];
      this.underGroup = [];
      console.log("huvud: ", event.mainGroup)
      this.form.stores = event.mainGroup
      console.log("under: ", event.underGroup)
      this.form.articleGroup = event.underGroup
    },
  },
};
</script>

<style scoped>
.createBtn {
  background: rgb(32, 183,248); color: white;
}
.createBtn:hover {
  color: white;
  background: rgb(28, 161, 218);
}
</style>
