<template>
  <div>
    <button @click="showModal" type="button" class="btn createBtn">+</button>
  <Modal v-show="isModalVisible" @close="closeModal">
    <template v-slot:header> Skapa Målgrupper </template>

    <template v-slot:body>
      <form @submit.prevent="onSubmit">
        <div class="form-group">
          <label>Namn</label>
          <input v-model="form.name" class="form-control" required />
        </div>
        <!-- <div class="form-group">
          <label>Huvudgrupp</label>
          <select
            v-model="form.stores"
            @change="onChangeMethod(form.stores.id)"
            class="form-select form-select-md"
            aria-label=".form-select-sm"
            required
          >
            <option
              v-for="{ id, name } in articles"
              :key="id"
              :value="{ id: id, name: name }"
            >
              {{ name }}
            </option>
          </select>
        </div> -->
        <div class="form-group">
          <label>Välj målgruppsartiklar</label><br>
          <button @click="showSelModal" type="button" class="btn createBtn">+</button>
          <SelectionModal @finishedData="logChange" v-if="isSelModalVisible" @close="closeSelModal">
             <template v-slot:header><h3>Välj huvudgrupper och artikelgrupper!</h3></template>
          </SelectionModal>

          <!-- <label>ArtikelGrupp</label>
          <select
            v-model="form.articleGroup"
            class="form-select form-select-md"
            aria-label=".form-select-sm"
            required
          >
            <option v-for="item in articleList" :key="item" :value="item">
              {{ item }}
            </option>
          </select> -->
        </div>
        <div class="form-group">
          <label>Från köp</label>
          <input
            v-model="form.firstPurchase"
            type="date"
            class="form-control"
            required
          />
        </div>

        <div class="form-group">
          <label>Till köp</label>
          <input
            v-model="form.lastPurchase"
            type="date"
            class="form-control"
            required
          />
        </div>

        <button @click="closeModal()" type="submit" class="btn createBtn mt-3">
          Skapa målgrupp
        </button>
      </form>
    </template>
  </Modal>
  </div>
</template>

<script>
import { createTarget, getGroup, useLoadGroup } from "@/firebase";
import Modal from "./Modal.vue";
import SelectionModal from "./TargetSelection.vue";
import { reactive } from "vue";
export default {
  components: {
    Modal,
    SelectionModal,
  },
  setup() {
    // Fetch the article groups.
    const articles = useLoadGroup();


    const form = reactive({
      name: "",
      stores: "",
      articleGroup: "",
      firstPurchase: "",
      lastPurchase: "",
    });

    const onSubmit = async () => {
      const todayDate = new Date();
      // Change the dates to Timestamp for firebase
      form.firstPurchase = new Date(form.firstPurchase);
      form.lastPurchase = new Date(form.lastPurchase);


      
      // Validation
      if (
        todayDate > form.firstPurchase &&
        form.firstPurchase < form.lastPurchase
      ) {
        if((form.stores.length + form.articleGroup.length) > 0) {
          form.firstPurchase = new Date(form.firstPurchase).getTime();
          form.lastPurchase = new Date(form.lastPurchase).getTime();
          await createTarget({ ...form });
          form.name = "";
          form.stores = "";
          form.articleGroup = "";
          form.firstPurchase = "";
          form.lastPurchase = "";
        } else {
          alert("Välj artikel!");
        }
      } else {
        alert("Fyll i allt!");
      }
    };

    return { form, onSubmit, articles };
  },
  methods: {
    async onChangeMethod(id) {
      // Is not used right now
      const groups = await getGroup(id);
      this.articleList = groups.articleGroups;
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    showSelModal() {
      this.isSelModalVisible = true;
    },
    closeSelModal() {
      this.isSelModalVisible = false;
    },
    logChange (event) {
      this.mainGroup = [];
      this.underGroup = [];
      console.log("huvud: ", event.mainGroup)
      this.form.stores = event.mainGroup
      console.log("under: ", event.underGroup)
      this.form.articleGroup = event.underGroup
    }
  },
  data() {
    return {
      articleList: [],
      isModalVisible: false,
      isSelModalVisible: false,
      mainGroup: [],
      underGroup: []
    };
  },
};
</script>

<style scoped>
.createBtn {
  background: rgb(32, 183,248); color: white;
}
.createBtn:hover {
  color: white;
  background: rgb(28, 161, 218);
}
</style>
