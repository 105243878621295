<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal">
        <header class="modal-header">
          <slot name="header"> Loading Title... </slot>
          <button type="button" class="btn-close" @click="close">x</button>
        </header>

        <section class="modal-body">
          <div class="w-100 mb-2">
            <h4>Huvudgrupper</h4>
            <!-- <input
              style="margin-right: 5px"
              type="text"
              v-model="search"
              placeholder="Sök"
            /> -->
          </div>
          <div
            class="btn-group"
            role="group"
            aria-label="Basic checkbox toggle button group"
            style=""
          >
            <div v-for="{ id, name } in findMain" :key="id" style="margin: 5px">
              <input
                type="checkbox"
                class="btn-check"
                :id="id"
                autocomplete="off"
                :value="name"
                v-model="checkedStores"
              />
              <label class="btn btn-outline-primary" :for="id">{{
                name
              }}</label>
            </div>
          </div>
          <div class="row mt-4">
            <h4>Artikelgrupper</h4>
            <div
              class="btn-group"
              role="group"
              aria-label="Basic checkbox toggle button group"
            >
              <div
                v-for="{ id, products, store } in findUnder"
                :key="id"
                style="margin: 5px"
              >
                <input
                  type="checkbox"
                  class="btn-check"
                  :id="products + ':' + store"
                  autocomplete="off"
                  v-model="checkedProducts"
                  :value="products + ':' + store"
                  @click="clickSel"
                />
                <label
                  class="btn btn-outline-primary productLabel"
                  :for="products + ':' + store"
                  >{{ products }}

                  <span class="storeLabel">{{ store }}</span>
                </label>
              </div>
              {{ findX }}
            </div>
          </div>
          <div class="stk btmButton">
            <button
              type="button"
              class="btn-confirmation"
              @click="selectionFinished"
            >
              Skicka
            </button>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import { useLoadGroup, getArticleData } from '@/firebase'
import readXlsxFile from 'read-excel-file'

export default {
  name: 'SelectionModal',
  props: ['editChecked'],
  setup() {
    const mapE = {
      Kod: 'kod',
      Beskrivning: 'desc',
    }

    const groups = useLoadGroup()
    const articleData = getArticleData()
    return { groups, articleData, mapE }
  },
  data() {
    return {
      checkedStores: [],
      checkedProducts: [],
      mainArticleList: [],
      underArticleList: [],
    }
  },
  computed: {
    findMain() {
      return this.mainArticleList.map((mainName) => {
        var singleObj = {}
        singleObj['id'] = mainName.id
        singleObj['name'] = mainName.name
        return singleObj
      })
    },
    findUnder() {
      return this.underArticleList.map((mainName) => {
        var singleObj = {}
        singleObj['id'] = mainName.id
        singleObj['store'] = mainName.products.name
        singleObj['products'] = mainName.name
        return singleObj
      })
    },
    findX() {
      console.log(this.editChecked)
      return this.fileRead(this.articleData)
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    clickSel() {
      console.log('clicked')
    },
    productSel(store, remove) {
      this.findUnder.filter((target) => {
        if (remove) {
          this.checkedStores.forEach((element) => {
            const resultName = target.store
              .toLowerCase()
              .includes(element.toLowerCase())
            if (resultName === true) {
              this.checkedProducts.push(`${target.products}:${element}`)
            }
          })
        } else {
          const resultName = target.store
            .toLowerCase()
            .includes(store.toLowerCase())
          if (resultName === true) {
            this.checkedProducts.push(`${target.products}:${store}`)
          }
        }
      })
    },
    fileRead(xlsxfile) {
      readXlsxFile(xlsxfile, { sheet: 'BK04 uppdelad', map: this.mapE }).then(
        ({ rows }) => {
          rows.forEach((element) => {
            console.log(element)
            var mainObj = {}
            var underObj = {}
            if (element.kod.length == 2) {
              // console.log('%c'+ element.kod + ' : ' + element.desc ,'background:red;color:#fff')
              mainObj.id = element.kod
              mainObj.name = element.desc

              this.mainArticleList.push(mainObj)
            } else if (element.kod.length == 3) {
              // console.log('%c'+ element.kod + ' : ' + element.desc ,'background:blue;color:#fff')
              underObj.id = element.kod
              underObj.name = element.desc

              const lastItem =
                this.mainArticleList[this.mainArticleList.length - 1]
              underObj.products = lastItem

              this.underArticleList.push(underObj)
            }
          })
        }
      )
    },
    selectionFinished() {
      const finalObj = {}
      finalObj['mainGroup'] = this.checkedStores
      finalObj['underGroup'] = this.checkedProducts
      this.$emit('finishedData', finalObj)
      this.$emit('close')
    },
  },
  watch: {
    checkedStores: {
      handler(newValues, previousValues) {
        var last = newValues[newValues.length - 1]

        if (newValues.length - previousValues.length < 0) {
          this.checkedProducts = []
          this.productSel(this.checkedStores, true)
        } else {
          this.productSel(last, false)
        }
      },
    },
    checkedProducts: {
      handler(newValues, previousValues) {
        if (newValues.length - previousValues.length < 0) {
          const difference = previousValues.filter(
            (x) => !newValues.includes(x)
          )
          const chosenStore = difference.toString().split(':')[1]
          var index = this.checkedStores.indexOf(chosenStore)
          if (index >= 0) {
            this.checkedStores.splice(index, 1)
          }
        }
      },
    },
  },
  mounted() {
    if (this.editChecked) {
      this.checkedStores = this.editChecked.mainGroup
      this.checkedProducts = this.editChecked.underGroup
    }
  },
}
</script>

<style scoped>
.stk {
  position: sticky;
}
.btmButton {
  bottom: 2rem;
  align-self: flex-end;
}
.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
}
.storeLabel {
  color: black;
  font-size: 9pt;
  position: relative;
  background: white;
  border: 1pt solid blue;
  border-radius: 3px;
  top: -10px;
  right: -10px;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  padding: 20px;
  position: relative;
  width: 60%;
  height: 80%;
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: rgb(32, 183, 248);
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: rgb(73, 73, 73);
  background: transparent;
}
.btn-confirmation {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 1rem 3rem;
  cursor: pointer;
  font-weight: bold;
  color: white;
  background: rgb(32, 183, 248);
  border: 1px solid rgb(32, 183, 248);
  border-radius: 2px;
}

.btn-green {
  color: white;
  background: rgb(32, 183, 248);
  border: 1px solid rgb(32, 183, 248);
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
