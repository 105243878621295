<template>
  <div>
    <div class="w-100 bg-white mt-5 rounded">
      <nav class="navbar navbar-light">
        <div class="container-fluid">
          <input
            style="margin-right: 5px"
            type="text"
            v-model="search"
            placeholder="Sök"
            class="searchbar"
          />
          <div class="d-flex">
            <TargetEdit ref="editModal" />
            <TargetCreate ref="createModal" />
          </div>
        </div>
      </nav>
    </div>
    <div class="card mt-1 p-2" style="min-height: 542px">
      <table class="table m-0">
        <thead>
          <tr>
            <th scope="col">Namn</th>
            <th scope="col">Huvudgrupp</th>
            <th scope="col">ArtikelGrupp</th>
            <th scope="col">Fr o m</th>
            <th scope="col">T o m</th>
            <th scope="col">Alternativ</th>
            <th @click="sortedItems(sortType)" scope="col">{{ sortType }}</th>
          </tr>
        </thead>
        <tbody>
          <Spinner v-if="isLoading" />
          <tr
            v-for="{
              id,
              name,
              stores,
              articleGroup,
              firstPurchase,
              lastPurchase,
            } in filteredList"
            :key="id"
            @click="onEdit($event, id)"
            class="itemrow"
          >
            <td>{{ name }}</td>
            <td>
              {{ stores[0] }}
            </td>
            <td class="tooltipx">
              {{ articleGroup[0] }}
              <span class="tooltiptext">
                <span v-for="article in articleGroup" :key="article">
                  {{ article.toString().split(":")[0] }}<br />
                </span>
              </span>
            </td>
            <td>{{ this.timeConvert(firstPurchase) }}</td>
            <td>{{ this.timeConvert(lastPurchase) }}</td>
            <td>
              <button
                style="
                  background: rgb(245, 110, 110);
                  border-color: rgb(245, 110, 110);
                "
                @click="deleteTarget(id)"
                type="delete"
                class="btn btn-danger"
              >
                Ta bort
              </button>
            </td>
            <td style="width: 1%"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { useLoadTarget, deleteTarget } from "@/firebase";
import { useRouter } from "vue-router";
import TargetCreate from "./TargetCreate.vue";
import TargetEdit from "./TargetEdit.vue";
import Spinner from "./misc/Spinner.vue";

export default {
  components: {
    TargetCreate,
    TargetEdit,
    Spinner
  },
  mounted() {
    this.isLoading = false;
  },
  setup() {
    const router = useRouter();
    const targets = useLoadTarget();

    return { targets, deleteTarget, router };
  },
  methods: {
    async sortedItems(sortType) {
      if (sortType === "ASC") {
        this.sortType = "DESC";
        this.targets = await this.targets.sort((a, b) => {
          return a.created - b.created;
        });
      } else {
        this.sortType = "ASC";
        this.targets = this.targets.sort((a, b) => {
          return b.created - a.created;
        });
      }
    },
    timeConvert(time) {
      var date = new Date(time._seconds * 1000);

      var myDateString =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2);
      return myDateString;
    },
    onEdit(event, id) {
      if (
        event.target.tagName !== "INPUT" &&
        event.target.tagName !== "BUTTON"
      ) {
        this.$refs.editModal.showModal(id);
      }
    },
  },
  data() {
    return {
      search: "",
      sortType: "DESC",
      isLoading: true,
    };
  },
  computed: {
    filteredList() {
      return this.targets.filter((target) => {
        const resultName = target.name
          .toLowerCase()
          .includes(this.search.toLowerCase());
        const resultGroup = "Inte tillagd!";
        if (target.stores.length > 0) {
          this.resultGroup = target.stores[0]
            .toLowerCase()
            .includes(this.search.toLowerCase());
        }
        if (resultName) {
          return resultName;
        } else {
          return resultGroup;
        }
      });
    },
  },
};
</script>

<style scope>
.icon {
  margin-right: 0.5rem;
}
.icon .fa-trash-alt {
  color: #fff;
  background-color: black;
  border-radius: 50%;
  padding: 10px 12px;
}
.icon .fa-pencil-alt {
  color: #fff;
  background-color: #3786ec;
  border-radius: 50%;
  padding: 10px 10px;
}
tr.itemrow:hover {
  background-color: #f8f9fa;
}
tr {
  color: rgb(40, 40, 40);
}
.card {
  border: 0;
  background: white;
}
.searchbar {
  border: none;
}
.searchbar:focus {
  outline: none;
  border-bottom: 1px solid rgb(200, 200, 200);
  border-top: none;
  border-left: none;
  border-right: none;
}
.tooltipx {
  position: relative;
}

.tooltipx .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: rgb(40, 40, 40, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltipx:hover .tooltiptext {
  visibility: visible;
}
</style>
